<template>
  <svg
    v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M12.713 3.47756C12.6501 3.33499 12.5496 3.21424 12.4232 3.12961C12.2969 3.04498 12.15 3 12 3C11.85 3 11.7031 3.04498 11.5768 3.12961C11.4504 3.21424 11.3499 3.33499 11.287 3.47756L9.03344 8.5899L3.69315 9.25319C3.54415 9.2716 3.40331 9.33431 3.2873 9.43389C3.17129 9.53347 3.08497 9.66576 3.03854 9.81509C2.99211 9.96443 2.98753 10.1246 3.02534 10.2765C3.06314 10.4285 3.14175 10.566 3.25185 10.6726L7.20151 14.4946L6.15324 20.0179C6.12413 20.1718 6.13774 20.3313 6.19246 20.4774C6.24719 20.6236 6.34074 20.7502 6.46206 20.8425C6.58338 20.9347 6.7274 20.9887 6.87708 20.9979C7.02676 21.0072 7.17586 20.9714 7.30673 20.8949L12 18.1447L16.6933 20.8949C16.8242 20.9718 16.9735 21.0078 17.1234 20.9986C17.2733 20.9894 17.4176 20.9354 17.5391 20.843C17.6606 20.7506 17.7542 20.6237 17.8089 20.4773C17.8635 20.3309 17.877 20.1712 17.8475 20.017L16.7993 14.4954L20.7481 10.6726C20.8583 10.566 20.9369 10.4285 20.9747 10.2765C21.0125 10.1246 21.0079 9.96443 20.9615 9.81509C20.915 9.66576 20.8287 9.53347 20.7127 9.43389C20.5967 9.33431 20.4558 9.2716 20.3069 9.25319L14.9658 8.58908L12.713 3.47756Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.15669 9.58227C9.50586 9.5389 9.80655 9.31522 9.94848 8.99326L12 4.33934L14.0507 8.99237C14.1926 9.31432 14.4932 9.53802 14.8424 9.58143L19.8008 10.198L16.1037 13.7769C15.8619 14.011 15.754 14.3512 15.8168 14.6819L16.7867 19.7906L12.5056 17.2819C12.1934 17.099 11.8066 17.099 11.4944 17.2819L7.21435 19.7899L8.18398 14.681C8.24676 14.3502 8.13886 14.0101 7.8969 13.7759L4.19938 10.198L9.15669 9.58227Z"
      stroke="currentColor"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
